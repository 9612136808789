import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { BiSolidImageAdd } from "react-icons/bi";
import { IoTrash, IoTriangleSharp, IoCaretDownOutline } from "react-icons/io5";
import { Carousel } from "react-responsive-carousel";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMsg } from "../../Component/Comman/Typography";
import premadeBotParams from "../../Component/Data/AICharacterData/premadeBotParams";
import { Modal } from '../../Component/Modal/Modal';
import { AIApiInstance } from "../../apis/AIAPI";
import ImageUpload from '../../assets/images/image_upload.jpg';
import { isRedbitUrl } from "../../utils/checkRedbitUrl";
import { theme } from "../../utils/config";
import { getUserIpInfo } from "../../utils/getUserCountry";
import { getSchedueledImageUrl } from "../../utils/images";
import { timeZones } from "../../utils/timezones";
import SchedueledMessages from "../SchedueledMessages/SchedueledMessages";
import { AIFormViewTypes, aiCharacterSchema, defaultSchedueledMessageState } from "./common";


const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "45vh",
        backgroundColor: "rgba(0,0,0,0.6)",
        borderColor: "transparent",
        borderRadius: "12px",
        height: "80vh",
        overflowY: "auto"
    },
    overlay: {
        backgroundColor: "rgba(4, 4, 35, .5)",
        backdropFilter: "blur(3px)"
    }
};

function CreateCharacter() {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors, touchedFields, isSubmitting },
        control
    } = useForm({ resolver: yupResolver(aiCharacterSchema) });

    const [showPrice, setShowPrice] = useState(false);
    const [isRedbit, setIsRedbit] = useState(false);
    const [isAdvance, setIsAdvance] = useState(true);
    const [view, setView] = useState(AIFormViewTypes.CHARACTER);
    const [schedueledMessages, setSchedueledMessages] = useState([]);
    const couponImageInput = useRef(null);
    const [showScheduleModal, setShowScheduleModal] = useState(false);

    useEffect(() => {
        setIsRedbit(isRedbitUrl());
    }, []);

    const price_currencies = [
        // { name: "KRW", text: "Korean Won" },
        // { name: "PKR", text: "Pakistani Rupees" },
        // { name: "USD", text: "United States Dollar" },
        // { name: "PHP", text: "Phillipine Pesos" },
        // { name: "VND", text: "Vietnamese Dong" },
        { name: "GEMS", text: "Gems" },
    ];

    const onSubmit = async (data) => {
        try {
            const formData = new FormData();

            let _schedueledMessages = schedueledMessages.map(({file, ...msg}) => {
                if (file) {
                    formData.append("schedueledMessagesImages", file);
                    return { ...msg, hasFile: true };
                }
                return { ...msg, hasFile: false };
            });
            formData.append("isRedbit", isRedbit);

            if (data.avatarUrl && data.avatarUrl.length > 0) {
                data.avatarUrl.forEach(a => {
                    formData.append("avatar", a.file);
                });
            }

            if (data.coverUrl && data.coverUrl.length > 0) {
                formData.append("coverUrl", data.coverUrl[0]);
            }
            console.log("data.hashtags", data.hashtags);
            if (data.hashtags && data.hashtags.length > 0) {
                let hashTagArray = data.hashtags.split(",").map((tag) => tag.trim());
                data.hashtags = JSON.stringify(hashTagArray);
            }

            console.log("data.hashtags:", data.hashtags);
            
            Object.keys(data).forEach(key => {
              const parsedKey = key;
              formData.append(key, data[parsedKey]);
            });

            formData.append('schedueledMessages', JSON.stringify(_schedueledMessages));

            const response = await AIApiInstance.createCharacter(
                formData,
            );

            if (response.status === 201) {
                navigate(`/chat?&type=ai&id=${response.data.data.aiCharacter._id}`);
            }
        } catch (error) {
            console.error(error);
            alert("Failed to create a new character"); // TODO: change to better error handling later
        }
    };

    const addMessage = () => {
        setSchedueledMessages(prev => [...prev, {...defaultSchedueledMessageState}]);
    }

    const removeSchedueledMessage = (index) => {
        setSchedueledMessages((prev) => {
            const filtered = prev.filter((msg, _i) => {
                return index !== _i;
            });
            return filtered;
        });
    };

    const updateMessage = (index, key, value) => {
        setSchedueledMessages(prev => {
            prev[index] = {...prev[index] , [key]: value};
            return [...prev];
        });
    }

    const handleSchedueledClick = () => {
        setShowScheduleModal(true);
    };


    const goBack = () => {
        if (schedueledMessages.length > 0) {
            const confirm = window.confirm("Discard Messages?");
            if (confirm) {
                setSchedueledMessages([]);
                setView(AIFormViewTypes.CHARACTER);
            }
        }
        if (schedueledMessages.length === 0) setView(AIFormViewTypes.CHARACTER);
    };

    const handleSubmitMessages = () => {
        for (let i = 0; i < schedueledMessages.length; i++) {
            const message = schedueledMessages[i];
            if (message.messageText === "") {
                alert("Message text cannot be empty.");
                return;
            }
        }
        setView(AIFormViewTypes.CHARACTER)
    };

    const removeImage = (uri) => {
        const avatars = getValues('avatarUrl') ?? [];
        const filtered = avatars.filter((image) => {
            return image.uri !== uri;
        })
        setValue('avatarUrl', filtered)
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const avatars = getValues('avatarUrl') ?? [];
                setValue('avatarUrl', [...avatars, { file: file, uri: reader.result, new: true }])
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImagePicker = () => {
        couponImageInput?.current?.click();
    };

    useWatch({
        control,
        name: [
            "avatarUrl",
            'coverUrl',
            "physicalAppearance",
            "description",
            "likes",
            "personalitySummary",
            "firstMessage",
            "scenario",
            "dialogueSample"
        ]
    });

    return (
        <>
            {view === AIFormViewTypes.CHARACTER ? (
                <div className="create-character-body">
                    <Header isAdvance={isAdvance} setIsAdvance={setIsAdvance} />
                    <div className="p-4">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="container-xxl mt-3 ">
                            <div className="row mb-3 ">
                                <div className="col-sm-12 col-md-5 ">
                                    <div className="card p-4 h-100 gap-3 create-character-form">
                                        <div className="col-sm-12 ">
                                            <Form.Label htmlFor="taxtno200" className="form-label">
                                                Character Profile
                                            </Form.Label>

                                            <div className="d-flex justify-content-center flex-column align-items-center">
                                                {/* {getValues("avatarUrl") && getValues("avatarUrl")?.length > 0 ? (
                                                    <img
                                                        className="avatar xxl rounded img-thumbnail shadow-sm mb-3 object-fit-cover"
                                                        src={URL.createObjectURL(getValues("avatarUrl")[0])}
                                                        alt="character-avatar"
                                                    />
                                                ) : (
                                                    <div className="avatar xxl rounded img-thumbnail shadow-sm mb-3" />
                                                )} */}
                                                <Carousel
                                                    showThumbs={false}
                                                    className="store_image_container rounded w-100"
                                                >
                                                    {[...getValues("avatarUrl") ? getValues("avatarUrl") : [], { type: "upload" }]?.map(
                                                        (image, index) =>
                                                            image?.type !== "upload" ? (
                                                                <div className="" key={"img" + index}>
                                                                    <div>
                                                                        <Button
                                                                            variant="dark"
                                                                            className="position-absolute top-0 start-0 ms-4 my-2 rounded px-2 py-1"
                                                                            onClick={() => removeImage(image.uri)}
                                                                        >
                                                                            <IoTrash size={16} color="white" />
                                                                        </Button>
                                                                        <div className="couponCarouselImage">
                                                                            <img
                                                                                src={image.uri}
                                                                                alt="carousel_img"
                                                                                className="object-fit-cover rounded"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    onClick={handleImagePicker}
                                                                    className="w-100"
                                                                    key={"img-none" + index}
                                                                >
                                                                    <div className="image_upload_container rounded w-100">
                                                                        <span style={{ color: 'var(--color-300)' }}>
                                                                            <BiSolidImageAdd
                                                                                size={28}
                                                                                className="me-2"
                                                                            />
                                                                            Upload Image
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )
                                                    )}
                                                </Carousel>
                                                <div>
                                                    <Form.Control
                                                        // {...register("avatarUrl")}
                                                        hidden
                                                        type="file"
                                                        className="form-control"
                                                        id="uploadPhoto"
                                                        accept="image/*"
                                                        ref={couponImageInput}
                                                        onChange={handleImageUpload}
                                                    />
                                                    {/* <label htmlFor="uploadPhoto" className="btn btn-primary">
                                                        Upload Photo
                                                    </label> */}
                                                </div>
                                            </div>

                                            {/* cover */}
                                            <div className="d-flex justify-content-start flex-row align-items-center my-2 gap-4">
                                                {getValues("coverUrl") && getValues("coverUrl")?.length > 0 ? (
                                                    <img
                                                        className="avatar xxl rounded img-thumbnail shadow-sm mb-3 object-fit-cover br-50"
                                                        src={URL.createObjectURL(getValues("coverUrl")[0])}
                                                        alt="character-avatar"
                                                    />
                                                ) : (
                                                    <div className="avatar xxl rounded img-thumbnail shadow-sm mb-3 br-50" />
                                                )}
                                                <div>
                                                    <Form.Control
                                                        {...register("coverUrl")}
                                                        hidden
                                                        type="file"
                                                        className="form-control"
                                                        id="uploadCover"
                                                        accept="image/*"
                                                    />
                                                    <label htmlFor="uploadCover" class="font-weight-bold">
                                                        Upload Cover
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 mt-2">
                                            <Form.Label htmlFor="item100" className="form-label required">
                                                Character Name
                                            </Form.Label>
                                            <Form.Control
                                                placeholder="Enter character name"
                                                required
                                                {...register("characterName")}
                                                type="text"
                                                className="form-control"
                                            />
                                            {touchedFields.characterName && errors.characterName && (
                                                <ErrorMsg msg={errors.characterName?.message} />
                                            )}
                                        </div>
                                        
                                        <div className="col-sm-12">
                                            <Form.Label className="form-label required">Age</Form.Label>
                                            <Form.Select
                                                {...register("age")}
                                                aria-label="Default select example"
                                                required
                                            >
                                                <option value="">--Select Age--</option>
                                                {Array.from({ length: 81 }, (_, index) => 19 + index).map((age) => (
                                                    <option key={age} value={age}>
                                                        {age}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            {touchedFields.age && errors.age && (
                                                <ErrorMsg msg={errors.age?.message} />
                                            )}
                                        </div>
                                        
                                        <div className="col-sm-12">
                                            <Form.Label className="form-label required">Gender</Form.Label>
                                            <Form.Select
                                                {...register("gender")}
                                                aria-label="Default select example"
                                                required
                                            >
                                                <option value="">--Select Gender--</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </Form.Select>
                                            {touchedFields.gender && errors.gender && (
                                                <ErrorMsg msg={errors.gender?.message} />
                                            )}
                                        </div>

                                        <div className="col-sm-12">
                                            <Form.Label className="form-label required">
                                                Physical Appearance
                                            </Form.Label>
                                            <Form.Select
                                                {...register("physicalAppearance")}
                                                onChange={(e) => {
                                                    setValue("physicalAppearance", e.currentTarget.value);
                                                }}
                                                aria-label="Default select example"
                                            >
                                                <option value={""}>--Select Physical Appearance--</option>
                                                {premadeBotParams(
                                                    getValues("characterName")
                                                        ? getValues("characterName")
                                                        : "character"
                                                ).physicalAppearance.map((value, i) => (
                                                    <option key={"appearance-" + i} value={value}>
                                                        {value}
                                                    </option>
                                                ))}
                                            </Form.Select>

                                            {isAdvance && (
                                                <Form.Control
                                                    value={getValues("physicalAppearance")}
                                                    onChange={(e) => {
                                                        setValue("physicalAppearance", e.currentTarget.value);
                                                    }}
                                                    as="textarea"
                                                    type="text"
                                                    className="form-control mt-3"
                                                    rows={3}
                                                />
                                            )}

                                            {touchedFields.physicalAppearance && errors.physicalAppearance && (
                                                <ErrorMsg msg={errors.physicalAppearance?.message} />
                                            )}
                                        </div>

                                        <div className="col-sm-12">
                                            <Form.Label className="form-label required">Description</Form.Label>
                                            <Form.Select
                                                {...register("description")}
                                                onChange={(e) => {
                                                    setValue("description", e.currentTarget.value);
                                                }}
                                                aria-label="Default select example"
                                            >
                                                <option value={""}>--Select Description--</option>
                                                {premadeBotParams(
                                                    getValues("characterName")
                                                        ? getValues("characterName")
                                                        : "character"
                                                ).description.map((value, i) => (
                                                    <option key={"desc-" + i} value={value} className="text-break">
                                                        {value}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            {isAdvance && (
                                                <Form.Control
                                                    value={getValues("description")}
                                                    onChange={(e) => {
                                                        setValue("description", e.currentTarget.value);
                                                    }}
                                                    as="textarea"
                                                    type="text"
                                                    className="form-control mt-3"
                                                    rows={3}
                                                />
                                            )}

                                            {touchedFields.description && errors.description && (
                                                <ErrorMsg msg={errors.description?.message} />
                                            )}
                                        </div>
                                        <div className="col-sm-12">
                                            <Form.Label className="form-label required">
                                                Personality Summary
                                            </Form.Label>
                                            <Form.Select
                                                {...register("personalitySummary")}
                                                aria-label="Default select example"
                                                onChange={(e) => {
                                                    setValue("personalitySummary", e.currentTarget.value);
                                                }}
                                            >
                                                <option value={""}>--Select Personality Summary--</option>
                                                {premadeBotParams(
                                                    getValues("characterName")
                                                        ? getValues("characterName")
                                                        : "character"
                                                ).personalitySummary.map((value, i) => (
                                                    <option key={"desc-" + i} value={value}>
                                                        {value}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            {isAdvance && (
                                                <Form.Control
                                                    value={getValues("personalitySummary")}
                                                    onChange={(e) => {
                                                        setValue("personalitySummary", e.currentTarget.value);
                                                    }}
                                                    as="textarea"
                                                    type="text"
                                                    className="form-control mt-3"
                                                    rows={3}
                                                />
                                            )}
                                            {touchedFields.personalitySummary && errors.personalitySummary && (
                                                <ErrorMsg msg={errors.personalitySummary?.message} />
                                            )}
                                        </div>

                                        <div className="col-sm-12">
                                            <Form.Label className="form-label required">Likes</Form.Label>
                                            <Form.Select
                                                {...register("likes")}
                                                aria-label="Default select example"
                                                onChange={(e) => {
                                                    setValue("likes", e.currentTarget.value);
                                                }}
                                            >
                                                <option value={""}>--Select Likes--</option>
                                                {premadeBotParams(
                                                    getValues("characterName")
                                                        ? getValues("characterName")
                                                        : "character"
                                                ).likes.map((value, i) => (
                                                    <option key={"desc-" + i} value={value}>
                                                        {value}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            {isAdvance && (
                                                <Form.Control
                                                    value={getValues("likes")}
                                                    onChange={(e) => {
                                                        setValue("likes", e.currentTarget.value);
                                                    }}
                                                    as="textarea"
                                                    type="text"
                                                    className="form-control mt-3"
                                                    rows={3}
                                                />
                                            )}
                                            {touchedFields.likes && errors.likes && (
                                                <ErrorMsg msg={errors.likes?.message} />
                                            )}
                                        </div>
                                        <div className="col-sm-12">
                                            <Form.Label className="form-label required">First Message</Form.Label>
                                            <Form.Select
                                                {...register("firstMessage")}
                                                aria-label="Default select example"
                                                onChange={(e) => {
                                                    setValue("firstMessage", e.currentTarget.value);
                                                }}
                                            >
                                                <option value={""}>--Select First Message--</option>
                                                {premadeBotParams(
                                                    getValues("characterName")
                                                        ? getValues("characterName")
                                                        : "character"
                                                ).firstMessage.map((value, i) => (
                                                    <option key={"desc-" + i} value={value}>
                                                        {value}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            {isAdvance && (
                                                <Form.Control
                                                    value={getValues("firstMessage")}
                                                    onChange={(e) => {
                                                        setValue("firstMessage", e.currentTarget.value);
                                                    }}
                                                    as="textarea"
                                                    type="text"
                                                    className="form-control mt-3"
                                                    rows={3}
                                                />
                                            )}
                                            {touchedFields.firstMessage && errors.firstMessage && (
                                                <ErrorMsg msg={errors.firstMessage?.message} />
                                            )}
                                        </div>
                                            {/**category  */}
                                        <div className="col-sm-12">
                                            <Form.Label className="form-label required">Category<IoCaretDownOutline size={16} color="white" /></Form.Label>
                                            <Form.Select
                                                {...register("category")}
                                                aria-label="Default select example"
                                                onChange={(e) => {
                                                    setValue("category", e.currentTarget.value);
                                                }}
                                            >
                                                <option value={""}>--Select Category-V <IoCaretDownOutline size={16} color="white" /></option>
                                                {premadeBotParams(
                                                    getValues("category")
                                                        ? getValues("category")
                                                        : "category"
                                                ).category.map((value, i) => (
                                                    <option key={"desc-" + i} value={value}>
                                                        {value}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            
                                            {touchedFields.category && errors.category && (
                                                <ErrorMsg msg={errors.category?.message} />
                                            )}
                                        </div>

                                        {/**hash tags  */}
                                        <div className="col-sm-12">
                                            <Form.Label className="form-label required">hash tags<IoCaretDownOutline size={16} color="white" /></Form.Label>
                                            
                                                <Form.Control
                                                    value={getValues("hashtags")}
                                                    onChange={(e) => {
                                                        setValue("hashtags", e.currentTarget.value);
                                                    }}
                                                    placeholder="separate hashtags with commas"
                                                    as="textarea"
                                                    type="text"
                                                    className="form-control mt-3"
                                                    rows={1}
                                                />
                                            
                                            {touchedFields.category && errors.category && (
                                                <ErrorMsg msg={errors.category?.message} />
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-7">
                                    <div className="card p-4 gap-3">
                                        {/* <Button onClick={handleSchedueledClick} className="p-3 scheduled-message-btn">
                                            <i class="fa fa-calendar pe-3" aria-hidden="true"></i>
                                            Scheduled Messages
                                        </Button> */}
                                        <span>
                                        {schedueledMessages.length > 0
                                            ? `${schedueledMessages.length} Messages`
                                            : "No Messages"}
                                        </span>
                                        <Form.Check
                                            type="switch"
                                            label="Make Character Public. (Character will be visible on explore page)"
                                            {...register("makeCharacterPublic")}
                                            onChange={(e) => {
                                                setValue("makeCharacterPublic", e.currentTarget.checked);
                                            }}
                                        />
                                        {/** only show adultContent in redbit.app */}

                                        <Form.Check
                                            type="switch"
                                            label="Character is adult content? (18+ content will show only on redbit.app)"
                                            {...register("adultContent")}
                                            onChange={(e) => {
                                                setValue("adultContent", e.currentTarget.checked);
                                            }}
                                        />
                                        {/* <Form.Check
                                            type="switch"
                                            label="Monetize"
                                            {...register("monetize")}
                                            onChange={(e) => {
                                                setValue("monetize", e.currentTarget.checked);
                                                setShowPrice(e.currentTarget.checked);
                                            }}
                                        /> */}
                                        {/* alert(getValues("monetize")) */}
                                        <div
                                            style={{ visibility: showPrice ? "visible" : "hidden" }}
                                            className="character-price col-sm-12 mt-2"
                                        >
                                            <Form.Label htmlFor="item100" className="form-label">
                                                Price in Gems (100 GEMS = 1 USD)
                                            </Form.Label>
                                            <Form.Control
                                                placeholder="Enter Price in Gems"
                                                required
                                                {...register("price")}
                                                type="number"
                                                className="form-control"
                                                defaultValue={0}
                                                min={0}
                                                onChange={(e) => {
                                                    setValue("price", e.currentTarget.value);
                                                }}
                                            />
                                            <Form.Label htmlFor="item100" className="form-label">
                                                Currency
                                            </Form.Label>
                                            <Form.Select
                                                {...register("currency")}
                                                aria-label="Default select example"
                                                onChange={(e) => {
                                                    console.log("e.currentTarget.value", e.currentTarget.value);
                                                    setValue("currency", e.currentTarget.value);
                                                }}
                                                defaultValue={"GEMS"}
                                            >
                                                {price_currencies.map((currency, i) => (
                                                    <option
                                                        selected={currency.name === "GEMS"}
                                                        value={currency.name}
                                                        key={i}
                                                    >
                                                        {currency.text}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </div>
                                        
                                        <div className="container-xxl">
                                            <div className="row align-items-center ">
                                                <div className="d-block justify-content-center">
                                                    <div>
                                                        <Button type="submit" disabled={isSubmitting} className="orange-gradient-btn fw-bold w-100 py-3">
                                                            {isSubmitting && (
                                                                <Spinner
                                                                    animation="border"
                                                                    size="sm"
                                                                />
                                                            )}
                                                            Create Character
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <Modal
                        setIsOpen={setShowScheduleModal}
                        modalIsOpen={showScheduleModal}
                        showClose={false}
                        styles={customStyles}
                        body={
                            <div className="d-flex flex-column gap-2 justify-content-between schedueledMessages-container p-2 pb-0">
                                <Button onClick={addMessage} className="scheduled-message-btn p-4">
                                    <i class="fa-solid fa-plus me-4" style={{color: "#ffffff", fontSize: "20px"}}></i>
                                    Add New Message
                                </Button>
                                <div className="d-flex flex-column gap-3">
                                    {schedueledMessages.map((msg, i) => (
                                        <SchedueledMessage
                                            key={`schedueledMessage${i}`}
                                            schedueledMessage={msg}
                                            removeSchedueledMessage={removeSchedueledMessage}
                                            updateMessage={updateMessage}
                                            index={i}
                                            update={false}
                                        />
                                    ))}
                                </div>
                                <div className="d-flex flex-row justify-content-center gap-3 py-3">
                                    <Button onClick={handleSubmitMessages} className="orange-gradient-btn py-3" style={{width: "50%"}}>Save</Button>
                                    <Button onClick={goBack} className="transparent-orange-gradient-btn py-3" style={{width: "50%"}}>Cancel</Button>
                                </div>
                            </div>
                        }>
                    </Modal>
                </div>
            </div>

            ) : (
                <SchedueledMessages
                    addMessage={addMessage}
                    schedueledMessages={schedueledMessages}
                    removeSchedueledMessage={removeSchedueledMessage}
                    updateMessage={updateMessage}
                    goBack={goBack}
                    submitMessages={handleSubmitMessages}
                    update={false}
                />
            )}
        </>
    );
}

const Header = ({ isAdvance, setIsAdvance }) => (
    <>
        <Link to={process.env.PUBLIC_URL + "/chat"}>
            <div className="d-flex align-items-center justify-content-between px-5 py-4" style={{background: "rgba(0,0,0,0.5)"}}>
                <div className="d-flex align-items-center">
                    <i className="fa fa-chevron-left me-3" />
                    <p className="mb-0 ">Back</p>
                </div>
                <h6>Create Character</h6>
            </div>
        </Link>
        <div className="justify-content-end d-flex w-100 pe-5 pt-3">
            <Form.Check
                type="switch"
                label="Advanced Mode"
                onChange={() => setIsAdvance((prevState) => !prevState)}
                checked={isAdvance}
            />
        </div>
    </>
);

export default CreateCharacter;

function SchedueledMessage({
    schedueledMessage = {},
    removeSchedueledMessage = (_) => {},
    updateMessage = (_, __, ___) => {},
    index,
    update = false,
    autoSelectTimzone = true
}) {
    const fileInputRef = useRef();

    useEffect(() => {
        if (autoSelectTimzone && !schedueledMessage?._id) {
            getUserIpInfo()
                .then(({ timezone }) => {
                    const foundTimeZone = timeZones.find((tz) => tz === timezone);
                    if (foundTimeZone) {
                        updateMessage(index, "timezone", foundTimeZone);
                    }
                })
                .catch(console.error);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoSelectTimzone, index, schedueledMessage?._id]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        updateMessage(index, "file", file);
    }

    const handleUploadClick = () => {
        fileInputRef?.current?.click();
    }

    const getImageUrl = () => {
        if (!update) {
            return schedueledMessage?.file
                ? URL.createObjectURL(schedueledMessage?.file)
                : ImageUpload;
        }
        if (schedueledMessage?.file) {
            return URL.createObjectURL(schedueledMessage?.file);
        }
        if (schedueledMessage?.messageImageUrl && schedueledMessage?.messageImageUrl !== "") {
            return getSchedueledImageUrl(schedueledMessage?.messageImageUrl);
        }
        return ImageUpload;
    };

    const handleImageDelete = () => {
        if (update && schedueledMessage?.messageImageUrl) {
            updateMessage(index, "messageImageUrl", '');
            return;
        }
        updateMessage(index, "file", null);
    };

    const showDeleteButton = () => {
        if (schedueledMessage?.messageImageUrl || schedueledMessage?.file) {
            return true;
        }
        return false;
    };

    return (
        <div className="create-schedule-form schedule-messages-container">
            <Form className="d-flex flex-column gap-2 p-4">
                <input ref={fileInputRef} type="file" onChange={handleFileChange} style={{ display: "none" }} />
                <div style={{ width: "150px", cursor: "pointer", position: "relative" }}>
                    <img src={getImageUrl()} alt="" style={{ width: "100%", height: "100%" }} onClick={handleUploadClick}/>
                    {showDeleteButton() && (
                        <i
                            style={{ cursor: "pointer", position: "absolute", right: "10%", top: "5%" }}
                            class="fa-solid fa-trash"
                            onClick={handleImageDelete}
                        ></i>
                    )}
                </div>
                
                <div>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                        type="text"
                        value={schedueledMessage.messageText}
                        onChange={(e) => {
                            updateMessage(index, "messageText", e.target.value);
                        }}
                        placeholder="Enter Message"
                    />
                </div>
                
                <div className="d-flex gap-2">
                    <div className="timezone-input">
                        <Form.Label>Timezone</Form.Label>
                        <Form.Select
                            type="text"
                            value={schedueledMessage.timezone}
                            onChange={(e) => {
                                updateMessage(index, "timezone", e.target.value);
                            }}
                        >
                            {timeZones.map((zone, i) => (
                                <option key={`zone${i}`} label={zone}>
                                    {zone}
                                </option>
                            ))}
                        </Form.Select>
                    </div>

                    <div>
                        <Form.Label>Time</Form.Label>
                        <Form.Control
                            type="time"
                            value={schedueledMessage.time}
                            onChange={(e) => {
                                updateMessage(index, "time", e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Form.Label>Frequency</Form.Label>
                    <Form.Select
                        type="text"
                        value={schedueledMessage.frequency}
                        onChange={(e) => {
                            updateMessage(index, "frequency", e.target.value);
                        }}
                    >
                        <option label="Daily">Daily</option>
                        {/* <option label="Weekly">weekly</option>
                        <option label="Monthly">monthly</option> */}
                    </Form.Select>
                </div>
                {schedueledMessage?.isSent ? (
                    <span> Cannot delete already sent message</span>
                ) : (
                    <Button onClick={() => removeSchedueledMessage(index)} className="orange-gradient-btn py-3">Delete Message</Button>
                )}
            </Form>
        </div>
    );
}